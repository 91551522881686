<template>
  <div class="content">
    <div class="searchWrapper">
      <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
      <!--（表单）选项区域-->
      <el-form ref="form" :model="formInline" label-width="100px" :inline="true">
        <div class="col_box">
          <div class="col_left">
            <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber" ref="plateNumber">
              <el-autocomplete
                ref="plateNumber"
                size="11"
                valueKey="plateNumber"
                class="inline-input"
                v-model="formInline.plateNumber"
                :fetch-suggestions="querySearchAsyncCar"
                placeholder="车牌号"
                :maxlength="8"
                :trigger-on-focus="false"
                @select="handleSelectCar"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Processing_record_ID')">
              <el-input
                v-model="formInline.parkOrderAppealId"
                placeholder="处理记录ID"
                style="width: 160px"
                :onkeyup="
                  (formInline.parkOrderAppealId = formInline.parkOrderAppealId.replace(
                    /[^\d]/g,
                    ''
                  ))
                "
                maxlength="19"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Business_order_type')">
              <el-select v-model="formInline.businessType" placeholder="请选择">
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="item in businessList"
                  :key="item.desc"
                  :label="item.desc"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Business_Order_ID')">
              <el-input
                v-model="formInline.businessId"
                :onkeyup="(formInline.businessId = formInline.businessId.replace(/[^\d]/g, ''))"
                placeholder="业务订单ID"
                style="width: 180px"
                maxlength="19"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态:" label-width="100px" v-if="tagTag == 1">
              <el-select v-model="formInline.status" placeholder="请选择">
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="timeStrTitle">
              <timeRangePick
                @timeChange="timeChange"
                ref="timeRangePicker"
                :defalutDate="defalutDate"
              />
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                pageNum = 1;
                searParkRecordList();
              "
              :loading="loading"
              >{{ $t('button.search') }}</el-button
            >
            <el-button icon="el-icon-delete" @click="reseat">{{ $t('button.reset') }}</el-button>
          </div>
        </div>
        <div class="col_box_boder"></div>
        <div class="col_box h44">
          <div class="col_left"></div>
          <div class="col_right mbd4">
            <el-button type="info" icon="el-icon-upload2" @click="exportHandler">{{ $t('button.export') }}</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <!--列表-->
    <div class="tableWrapper bgFFF paddingB10">
      <el-table :data="tableData" style="width: 100% !important">
        <el-table-column
          align="center"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
        >
          <template slot-scope="scope">
            <span
              v-if="item.prop == 'parkOrderAppealId'"
              v-clipboard:copy="scope.row.parkOrderAppealId"
              v-clipboard:success="onCopy"
              class="orderNumberStyle"
            >
              <p>{{ scope.row.parkOrderAppealId }}</p>
            </span>
            <span
              v-else-if="item.prop == 'businessId'"
              v-clipboard:copy="scope.row.businessId"
              v-clipboard:success="onCopy"
              class="orderNumberStyle"
            >
              <p>{{ scope.row.businessId }}</p>
            </span>
            <span v-else>
              <p>
                {{ item.formatter ? item.formatter(scope.row) : scope.row[item.prop] }}
              </p>
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('searchModule.state')" fixed="right" width="">
          <template slot-scope="scope">
            <span>
              <!-- <p>{{ scope.row.status == 1 ? '已完成': '待审核'}}</p> -->
              <p>{{ scope.row.statusName }}</p>
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="80">
          <template slot-scope="scope">
            <el-button type="text" style="padding: 0" @click="seeDetails(scope.row.parkOrderAppealId)">{{
              tagTag == 2 ? "审核" : "查看"
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <processPicture :dialogVisible="dialogVisible" @consoleDetails="consoleDetails"></processPicture> -->
    <processDetails
      ref="processDetails"
      :dialogVisibleDetails="dialogVisibleDetails"
      @consoleDetails="consoleDetails"
      :tableDetails="tableDetails"
    ></processDetails>
    <export-download
      :defalutDate="exportTime"
      :exprotDialog="exprotDialog"
      :exportForm="exportForm"
      :timeStrTitle="timeStrTitle"
      :businessList="businessList"
      @exportFlag="exportFlag"
      @closeFlag="exprotDialog = false"
    >
    </export-download>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import processDetails from "@/components/exceptionHanding/processDetails";
import ExportDownload from "@/components/uploadFile/exportDownload";
import { setIndex, dateFormat, exportExcelNew } from "@/common/js/public.js";
export default {
  name: "examine",
  data() {
    return {
      timeStrTitle: "",
      tagTag: "",
      businessList: [],
      statusList: [
        { label: "全部", value: "" },
        { label: "待审核", value: "0" },
        { label: "已完成", value: "1" },
        { label: "已驳回", value: "2" },
      ],
      dialogVisible: false,
      dialogVisibleDetails: false,
      defalutDate: [],
      pageNum: 1,
      total: 0,
      pageSize: 15,
      loading: false,
      tableData: [],
      formInline: {
        parkOrderAppealId: "",
        businessType: "",
        businessId: "",
        status: "",
        startTime: "",
        endTime: "",

        // areaId: "",
        // streetId: "",
        // operationId: "",
        // parkId: "",
        // parkType: "-1",
        // parkName: "",
        // plateNumber: "",
        // entryOrExit: 1,
        // mobile: "",
        // refundRecordId: "",
        // input1: "",
      },
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "parkOrderAppealId",
          label: "处理记录ID",
          width: "180",
        },
        {
          prop: "handleTime",
          label: this.$t("list.processing_time"),
          width: "180",
        },
        {
          prop: "handleOperatorName",
          label: this.$t("list.Processing_personnel"),
          width: "120",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "120",
        },
        {
          prop: "businessTypeName",
          label: "业务订单类型",
          width: "120",
        },
        {
          prop: "businessId",
          label: "业务订单ID",
          width: "180",
        },
        {
          prop: "appealTypeName",
          label: "更改项",
          width: "140",
        },
        {
          prop: "originValue",
          label: "更改项初始值",
          width: "180",
        },
        {
          prop: "currentValue",
          label: "更改项更新值",
          width: "180",
        },
      ],
      tableDetails: [
        {
          prop: "parkRecordId",
          label: this.$t("list.Parking_Order_ID"),
          width: "180",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "110",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "180",
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: "180",
        },
        {
          prop: "strExitTime",
          label: this.$t("list.Appearance_time"),
          width: "180",
        },
        // {
        //   prop: "shouldPay",
        //   label: "应收金额(元)",
        //   width: "",
        //   formatter: (row, column) => {
        //     if (row.shouldPay) {
        //       return Number(row.shouldPay / 100).toFixed(2);
        //     } else {
        //       return "0.00";
        //     }
        //   },
        // },
        // {
        //   prop: "lackMoney",
        //   label: "欠费金额(元)",
        //   width: "",
        //   formatter: (row, column) => {
        //     if (row.lackMoney) {
        //       return Number(row.lackMoney / 100).toFixed(2);
        //     } else {
        //       return "0.00";
        //     }
        //   },
        // }
      ],
      exprotDialog: false, // 导出弹窗
      exportForm: {
        businessType: 0,
      },
    };
  },
  watch: {},
  methods: {
    // headerTitle(row, rowIndex) {
    //     return 'headerTitleS'
    // },
    // rowItem(row, rowIndex) {
    //   let dataRow = row.rowIndex % 2;
    //   if (dataRow == 1) {
    //       return 'rowItem'
    //   } else {
    //       return 'rowItem2'
    //   }
    // },
    reseat() {
      this.formInline.parkOrderAppealId = "";
      this.formInline.businessType = "";
      this.formInline.businessId = "";
      this.formInline.status = "";
      this.$refs.timeRangePicker.resetTime();
      this.formInline.plateNumber = "";
      this.formInline.carId = "";
      this.pageNum = 1;
      this.searParkRecordList();
    },

    /**
     * 导出
     */
    exportHandler() {
      this.exprotDialog = true;
    },

    exportFlag(val) {
      if (!val.startTime && !val.endTime) {
        this.$message({
          type: "warning",
          message: "发起时间不能为空！",
        });
        return;
      }
      if (val) {
        let data = {
          businessType: val.businessType,
          handleTimeStart: val.startTime,
          handleTimeEnd: val.endTime,
          parkOrderAppealId: this.formInline.parkOrderAppealId,
          businessId: this.formInline.businessId,
          status: this.tagTag == 2 ? "0" : this.formInline.status,
          carId: this.formInline.carId,
        };
        // exportExcelNew('/acb/2.0/orderAppeal/export', data, 'get');
        this.$axios
          .get("/acb/2.0/orderAppeal/export", {
            data,
          })
          .then((res) => {
            if (res.state === 0) {
              this.$message({
                type: "success",
                message: "成功添加下载列表！",
              });
              this.exprotDialog = false;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      }
    },

    // 业务订单类型
    getOrderAppeal() {
      let opt = {
        method: "get",
        url: "/acb/2.0/orderAppeal/businessOrderTypeEnum",
        data: {},
        success: (res) => {
          this.businessList = res.value;
        },
      };
      this.$request(opt);
    },
    consoleDetails() {
      this.dialogVisibleDetails = false;
      this.searParkRecordList();
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
      this.exportTime = timeArr;
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searParkRecordList();
    },
    seeDetails(data) {
      console.log("scope-->", data);
      this.dialogVisibleDetails = true;
      this.$refs.processDetails.parkOrderAppeal(data, this.tagTag);
    },
    // 停车记录
    refundRecordIdFn() {
      //   this.$axios.get('/acb/2.0/parkRecord/' + this.rowData3).then(res => {
      //   })
    },
    // 搜索
    searParkRecordList() {
      this.searParkRecordListFun();
      // let flag = this.showLog();
      // if (flag) {
      //   this.flag = false;
      // }
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
    searParkRecordListFun() {
      console.log("tagTag", this.tagTag);
      let opt = {
        method: "get",
        url: "/acb/2.0/orderAppeal/list",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          parkOrderAppealId: this.formInline.parkOrderAppealId,
          businessType: this.formInline.businessType,
          businessId: this.formInline.businessId,
          // handleTimeStart: this.tagTag == 2 ? '' : this.formInline.startTime,
          // handleTimeEnd: this.tagTag == 2 ? '' : this.formInline.endTime,
          // appealTimeStart: this.tagTag == 2 ? this.formInline.startTime : '',
          // appealTimeEnd: this.tagTag == 2 ? this.formInline.endTime : '',
          status: this.tagTag == 2 ? "0" : this.formInline.status,
          handleTimeStart: this.formInline.startTime,
          handleTimeEnd: this.formInline.endTime,
          carId: this.formInline.carId,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    get7Day(data) {
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * data);
      return dateFormat(start, "yyyy-MM-dd") + " 00:00:00";
    },

    handleSelectCar(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      //   this.pageNum = 1;
      //   this.searParkRecordList();
    },

    querySearchAsyncCar(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
  },

  created() {
    console.log("route", this.$route);
    if (this.$route.name == "exceptionOrder_sensor") {
      this.tagTag = 2;
      this.timeStrTitle = "处理时间";
      this.tableCols[2].label = "处理时间";
    } else {
      this.tagTag = 1;
      this.timeStrTitle = "发起时间";
      this.tableCols[2].label = "发起时间";
    }
    this.defalutDate = [this.get7Day(7), this.dataTimeRest() + " 23:59:59"];
    this.exportTime = [this.get7Day(7), this.dataTimeRest() + " 23:59:59"];
    this.formInline.handleTimeStart = this.defalutDate[0];
    this.formInline.handleTimeEnd = this.defalutDate[1];
    this.formInline.appealTimeStart = this.defalutDate[0];
    this.formInline.appealTimeEnd = this.defalutDate[1];
    console.log(this.formInline);
  },
  mounted() {
    this.getOrderAppeal();
    this.searParkRecordList();
  },
  components: {
    timeRangePick,
    // processPicture
    processDetails,
    ExportDownload,
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.processingStyle{
  // width: 99%;
  // background: #FFFFFF;
  // border-radius: 8px;
  // padding 16px
  // box-sizing: border-box
}


.pagerWrapper {
  text-align: right;
  margin-top: 28px;
  font-size: 12px;
}

.searchWrapper .el-form {
  padding-top: 23px;
}

.optionAreaOne {
  display: flex;
  padding-left: 8px
}

.optionAreaThree {
  display: flex;
  padding-left: 8px
}

.optionAreaThree2 {
  display: flex;
  justify-content: right;
}

.title {
  margin-bottom: 15px;
}
.titlecontent{
  font-size: 14px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #2F2F51;
}
.warning {
  color: red;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.titleh1 {
  margin: 10px 10px;
  font-size: 15px;
}
.buts {
  height: 40px;
  margin-right: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.but {
  height: 40px;
  margin-right: 15px;
  margin-bottom: 15px;
  text-align: center;
  border: 1px solid #527BFF ;
  color #527BFF
}
.orderNumberStyle{
    color: #0f6eff
}
.el-table>>> .headerTitleS th {
    background: #F3F7FF;
    box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.85);
  }
.el-table>>> .rowItem{
    background: #F9FBFF;
    box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.08);
    height: 50px;
    padding :0px;
  }
  .el-table>>> .rowItem2{
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.08);
    height: 50px;
    padding :0px;
  }
</style>
